export default {
  noData: ' No Data ',
  operation: ' Operation ',
  delete: ' Delete ',
  deleteSuccess: ' Delete Success！',
  details: ' Details ',
  update: ' Update ',
  updateSuccess: ' Update Success ',
  edit: ' Edit ',
  query: ' Query ',
  search: ' Search ',
  reset: 'Reset ',
  export: 'Export',
  select: 'Select ',
  yl: 'Initta',
  newEdition: ' New Edition ',
  password: 'Password',
  changePassword: ' Change Password ',
  oldPassword: ' Old Password ',
  newPassword: ' New Password ',
  confirmationNewPassword: ' Confirm New Password ',
  cancel: ' Cancel ',
  save: ' Save ',
  yes: ' Yes ',
  no: ' No ',
  saveSuccess: ' Save Success ',
  cancelSuccess: ' Cancel Success ',
  submit: 'Submit',
  confirm: ' Confirm ',
  noChange: ' Do not Change Upload ',
  changeUpload: ' Change Upload ',
  newadd: ' Newly Added ',
  inputNewPassword: 'Please enter a new password',
  newdifferentOld: ' The new password cannot be the same as the old one',
  twicePasswordDifferent: ' The two passwords are inconsistent, please confirm and re-enter ',
  passwordModifiedSuccess: ' Password changed successfully！',
  passwordDifferentAccount: ' The password cannot be the same as the login account ',
  passwordRules:
    ' The password consists of 8-16 upper and lower case letters, numbers and special symbols (only the following special characters are supported:~! @ # $% ^&* () _?) form ',
  spaceCapacity: ' Space Capacity ',
  signOut: ' Sign Out ',
  more: ' More ',
  tips: ' Tips ',
  refresh: ' Refresh ',
  backList: ' Back List ',
  previous: ' Previous ',
  nextPage: 'Next',
  not: 'No',
  to: 'To',
  startDate: ' Start Date ',
  endDate: ' End Date ',
  isCancel: 'Cancel or Not',
  download: ' Download ',
  downloadTool: ' Download Tool ',
  upload: ' Upload ',
  newSuccess: ' New Success ',
  editSuccess: ' Edit Success',
  addSuccess: 'Add Succes',
  uploadFiles: ' Upload Files ',
  systemError: ' System Error ',
  index: ' Index ',
  ID: 'ID',
  fileDownloading: ' Source File Downloading ',
  copySucceeded: ' Copy Success',
  copy: ' Copy',
  placeholder: ' Placeholder ',
  pleaseEnter: ' Please Enter ',
  succeeded: ' Operation Succeeded ',
  leastOne: ' Please select at least one piece of data ',
  yes: ' Yes ',
  no: ' No ',
  closeOther: ' Close Other Label ',
  closeRightOther: ' Close Right Label ',
  getDeviceLocalLogs: 'Get Device Local Logs',
  deviceOfflineTip:
    'The device is offline, please check if the device has installed an application and network connection.',
  deviceUploadTip: 'Device data uploading, please keep the network connection normal.',
  connecting: 'Connecting...',
  connectionSuccess: 'The connection success.',
  connectionFail: 'The connection failed. Please check the network environment!',
  connectionClean: 'The connection cleaned.',
  step: 'Step',
  selectOrgan: 'Select Organ',
  selectMerchant: 'Select Merchant',
  customer: 'Customer',
  sort: 'Sort',
  spaceDetails: {
    spaceDetails: ' Space Capacity Details',
    total: ' Total ',
    video: ' Video ',
    photo: ' Photo ',
    installBag: 'Installation Package',
    resourceName: 'Resource Name',
    resourceSize: ' Resource Size ',
    resourceType: ' Resource Type '
  },
  loginPage: {
    login: ' Login ',
    loggingIn: ' Logging in...',
    accountPlaceholder: ' Please enter the account number ',
    passwordPlaceholder: ' Please input a password ',
    verificationCode: ' Please enter the graphic verification code ',
    customerPlatform: ' Customer Platform ',
    sdyPlatform: 'SEDInitta IOT Cloud Platform',
    longTerm: ' Long-Term ',
    day: 'Day',
    year: 'Year',
    expired: ' Expired ',
    expiredMsg: ' Login has expired, please log in again ',
    leave: ' Leave '
  },
  adminHomePage: {
    deviceTotal: ' Device Total ',
    today: ' Today ',
    month: ' Month ',
    pos: 'POS Series ',
    selfHelp: ' Selfhelp Series ',
    commercialDisplay: ' Commercial Display Series ',
    deviceModel: ' Proportion of equipment models ',
    tower: ' Set ',
    unit: ' Unit ',
    serviceProvider: ' Proportion of service providers equipment ',
    devGroup: ' Proportion of equipment groups ',
    devOS: ' Device OS Version ',
    first: ' First ',
    second: ' Second ',
    third: ' Third ',
    fourth: 'Fourth',
    fifth: ' Fifth ',
    devFirmware: ' Proportion of device firmware versions',
    devRegion: ' Regional distribution of equipment ',
    devReception: ' Device Foreground App ',
    common: 'common'
  },
  deviceList: {
    sn: 'SN No',
    store: ' Store ',
    affiliatedStore: ' Affiliated Store ',
    onLine: ' On-line ',
    peripheralName: ' Peripheral Name ',
    peripheralType: ' Peripheral Type ',
    bindingDevice: ' Binding a Single Device ',
    bindingBatchDevice: ' Bind Batch Devices ',
    bindingBatchDeviceTemplate: ' Batch bind device templates ',
    deviceType: ' Equipment Model ',
    deliveryCustomer: ' Delivery Customer ',
    customerId: '  Customer ID  ',
    customer: ' Customer ',
    deviceGroup: ' Equipment Group ',
    deviceStatus: ' Equipment Status ',
    firmwareVersion: ' Firmware Version ',
    onlineTimes: 'Online Frequency ',
    lastOnlineTime: ' Last Online Time ',
    createdTime: ' Created Time ',
    detailedAddress: ' Detailed Address ',
    address: ' Address ',
    retrieveTips:
      ' After downloading the file retrieval tool, open the download directory and manually double-click the file retrieval tool. Enter "Address" and "Session" to retrieve the file. The session is valid for 15 minutes. After timeout, please restart the session. ',
    retrieveSdTips:
      ' Please download the file retrieval tool and enter the ip address and password according to the steps to connect. This connection is valid for two minutes.',
    conversation: ' conversation ',
    remote: ' Remote Assistance ',
    sedinittaRemote: ' Remote Desktop',
    sedinittaRetrieve: ' Remote File',
    closeWifi: 'Close Wi-Fi',
    getLocation: ' Get Location ',
    noLocation: ' Unable to obtain location information, please enter it manually ',
    alreadyCloseWifi: 'Already Close Wi-Fi',
    closeGps: ' Turn Off Positioning ',
    openGps: ' Enable Positioning ',
    closeVirtualKeys: ' Turn Off Virtual Key ',
    alreadyCloseVirtualKeys: ' Virtual keys turned off ',
    openVirtualKeys: ' Turn On Virtual Key ',
    alreadyOpenVirtualKeys: ' Virtual key turned on ',
    restart: ' Restart ',
    restartSuccess: ' Restart Success ',
    restoreFactorySettings: ' Restore Factory Settings ',
    restoreFactorySettingsSuccess: ' Restore factory settings success ',
    setTimeZone: ' Set Time Zone ',
    setSleep: ' Set Sleep ',
    shutdown: ' Shutdown ',
    transferFiles: ' Transfer Files ',
    filesTransfer: ' Files Transfer to Device',
    screenBrightnessSetting: ' Screen Brightness Setting ',
    fontSizeSettings: ' Font Size Settings ',
    mainScreen: ' Main Screen ',
    secondaryScreen: ' Secondary Screen ',
    isNeedRemote: ' Do you want remote assistance？',
    printer: ' Printer ',
    scanningDevice: ' Scanning Device',
    camera: ' Camera',
    electronicScales: 'Electronic Scales',
    wantTo: ' Want to ',
    wantTouninstall: ' Want to uninstall ',
    deviceIsRemote: ' Device is remote ',
    deviceIsOffline: ' Device is offline ',
    isDeleteDevice: ' Do you want to delete the selected device？',
    isDeleteThisDevice: ' Do you want to delete this device？',
    basicDeviceDetails: ' Details of basic equipment ',
    logDownload: ' Log Download ',
    basicInfo: ' Basic Information ',
    CPUStorageInfo: ' CPU and Storage information ',
    appsInstalled: ' Apps Installed ',
    gray: ' Grayscale ',
    versionNo: ' Version No ',
    packageName: ' Package Name ',
    selfStart: ' Self Start ',
    selfStartSetting: ' Self Start Setting ',
    dominateScreen: ' Dominate The Screen ',
    dominateScreenSetting: ' Dominate Screen Setting ',
    peripheralInfo: ' Peripheral Information ',
    peripheralConnectionType: ' Peripheral Connection Type ',
    peripheralStatus: ' Peripheral Status ',
    firmwareVersion: ' Firmware Version ',
    extranetIP: ' Internet IP Address ',
    IPResolution: ' IP Resolution Address ',
    shutdownTime: ' Shutdown Time ',
    intranetIP: ' Intranet IP Address ',
    startupTime: ' Startup Time ',
    cloudPlatformVersion: ' Cloud Platform Version ',
    systemVersion: ' System Version ',
    CPUUtilization: ' CPU Utilization ',
    kernelNumber: ' Number Of Cores ',
    memory: ' Memory ',
    disk: ' Disk ',
    cpuTemperature: ' CPU Temperature ',
    offLine: ' Off-line ',
    uninstallSuccee: ' Uninstall Succeeded ',
    uninstallWaiting: 'Uninstalling,the uninstallation process takes about 5 seconds',
    printerNormal: ' The printer is normal ',
    printerException: ' The printer is not connected or powered on ',
    printerMismatch: ' Printer and call library do not match ',
    printHeadOpen: ' Print Head Open ',
    cutterNotReset: ' Cutter not reset ',
    printHeadOverheating: ' Print head overheating ',
    blackMarkError: ' Black mark error ',
    normal: ' Normal ',
    abnormal: ' Abnormal ',
    available: ' Available ',
    z: ' Total ',
    logType: ' Log Type ',
    logSize: ' Log Size ',
    operationTime: ' Operation Time ',
    androidVersion: ' Android Version Number ',
    otaUpgrade: ' Ota Upgrade ',
    appIssued: ' Application Distribution ',
    other: ' Others ',
    installFail: ' Install Fail ',
    noDownload: ' No Download！',
    reset: ' Reset ',
    timedRestart: ' Timed Restart ',
    timedSwitch: ' Timed Switch Machine ',
    retrieve: ' The file to retrieve ',
    everyDay: ' Every Day ',
    weekly: ' Weekly ',
    everyMonth: ' Every Month',
    customize: ' Customize ',
    executionMode: ' Execution Mode ',
    executionDate: ' Execution Date ',
    executionTime: ' Execution Time ',
    mon: ' MON ',
    tue: ' TUE ',
    wed: ' WED ',
    thu: ' THU ',
    fri: ' FRI ',
    sat: ' SAT ',
    sun: ' SUN ',
    filter: ' Enter keywords for filtering ',
    task: ' Task ',
    taskName: ' Task Name ',
    isStatus: ' Command issuing status ',
    addSubgroup: ' Add Subgroup ',
    rename: ' Rename ',
    assignAdmin: ' Assign Administrator ',
    moveUp: ' Move Up ',
    moveDown: ' Move Down ',
    delGroup: ' Are you sure you want to delete this group ',
    role: ' Role ',
    char30: ' Up to 30 characters can be set ',
    scheduledRestart: ' Scheduled Restart ',
    restartTime: ' Restart Time ',
    move: ' Can be dragged at the same level ',
    executionTimeTip: ' Please select the execution time ',
    selectNums: ' A total of {nums} devices were selected, ',
    confirmTask:
      ' Confirm to delete the task. Once the task is deleted, instructions will no longer be executed. ',
    DisStatus0: ' To be issued ',
    DisStatus1: ' In progress ',
    RestrictedInstall: ' Restricted installation of applications ',
    AllowInstall: ' Allow app installation ',
    AllowLocalInstall: ' Allow local installation of apps ',
    RestrictedInstallTip:
      ' Are you sure to set the selected device to not allow local installation of apps? ',
    AllowInstallTip:
      ' Are you sure to set the selected device to allow local installation of apps? ',
    OperationTips: ' Command issuance completed, {sucessNum} successful and {errNum} failed '
  },
  deviceModal: {
    bindingDevice: ' Binding Device ',
    editDevice: ' Edit Device ',
    selectCustomer: ' Select Customer ',
    selectGrouping: ' Select Grouping ',
    selectDeviceType: ' Select Device Type ',
    selectDeviceModel: ' Select Device Model ',
    pSelectDeviceModel: ' Please select the device model ',
    pleaseEnterSNNumber: ' Please enter SN number ',
    pleaseEnterDetailedAddress: ' Please enter the detailed address',
    downloadTemplate: ' Download Template ',
    dropFilesHere: ' Drop files here ',
    or: 'Or',
    clickUpload: ' Click Upload ',
    importFailedData: ' Import failed data',
    failureReason: ' Failure reason ',
    pleaseUploadFile: ' Please upload the file ',
    importSuccee: ' Import completed, successfully imported ',
    devices: ' Devices ',
    strip: ' Strip ',
    dataImportFailed:
      ' Data import failed. Please check the data format or ensure that the device has been successfully activated online.',
    viewImportFailed: ' View data failed to import ',
    selectFile: ' Select File ',
    fileDescription:
      ' The size of a single file cannot exceed 300M, and the file is saved under the "/Download/" directory ',
    fileSize: ' The size of the uploaded file cannot exceed 300MB!',
    transferSuccess: ' Transfer Succeeded！',
    small: ' Small ',
    default: ' Default ',
    large: ' Large ',
    maximum: ' Maximum ',
    settingSuccess: ' Setting Succeeded！',
    pleaseSelectThutdownTime: ' Please select the shutdown time ',
    shutdownImmediately: ' Shut down immediately ',
    timedShutdown: ' Timed Shutdown ',
    undoShutdown: ' Undo Shutdown ',
    permanent: ' Permanent ',
    once: ' Once ',
    sleepSettings: ' Sleep Settings ',
    never: ' Never ',
    second: ' Second ',
    minute: ' Minute ',
    pleaseSelectTimeZone: ' Please select a time zone！',
    selectArea: ' Select Area ',
    repeatTip:
      ' {nums} devices with a history of being shipped out have been detected in the file. Do you want to change the existing device shipping information? ',
    exportRepeatData: ' Export duplicate outbound data ',
    exportRepeatDataName: 'Repeated outbound data.xlsx',
    exportRepeatData2: ' Export failed data ',
    exportRepeatDataName2: 'Equipment outbound failure data.xlsx'
  },
  deviceType: {
    modelName: ' Equipment model and name ',
    modelImg: ' Model Picture ',
    deleteSelectedModel: ' Do you want to delete the selected model?',
    deleteSelectedThisModel: ' Do you want to delete this model?',
    newDevice: ' New Equipment Model ',
    editDevice: ' Edit Device Model ',
    productPange: ' Product Range ',
    selectProductPange: ' Please select a product family ',
    deviceImg: ' Device Picture ',
    pleaseUploadPicture: ' Please upload the picture ',
    pleaseEnterModel: ' Please enter the model ',
    signFileName: ' Signature File Name ',
    selectSignFileName: ' Please select a signature file name',
    remark: ' Remark '
  },
  activeDevice: {
    activationTime: ' Activation Time ',
    state: ' State ',
    todayNew: ' New Today ',
    weekNew: ' New This Week ',
    monthNew: ' New This Month'
  },
  deviceRoom: {
    bindSingleEquipment: ' Equipment Outbound ',
    bindBatchEquipment: ' Equipment batch outbound ',
    changeCustomers: ' Replacing outbound customers ',
    deliveryTime: ' Delivery Time ',
    deleteIssueRecord: ' Do you want to delete the issue record？',
    newIssueEquipment: ' New Issue Equipment ',
    editIssueEquipment: ' Edit Stock-out Equipment ',
    issueCustomer: ' Issue Customer ',
    operatingSystem: ' Operating System ',
    selectOperatingSystem: ' Select Operating System ',
    screen: ' Screen',
    selectScreen: ' Select Screen ',
    selectPrinter: ' Select Printer ',
    selectScanningDevice: ' Select Scanning Device ',
    selectCamera: ' Select Camera ',
    selectElectronicScales: ' Select Electronic Scales ',
    android: ' Android ',
    windows: 'Windows',
    singleScreen: 'Single Screen ',
    dualScreen: ' Dual Screen ',
    batchAddIssueEquipment: ' Batch add stock-out equipment ',
    batchAddIssueEquipmentTemplate: ' Batch Add Issue Equipment Template ',
    deliveryEquipment: ' Issue Equipment ',
    noVer: ' Version not obtained ',
    noVersion: ' Unable to obtain firmware version number ',
    noVersion2: ' No Android version obtained ',
    selectNone: 'No Select',
    fail: ' Fail',
    changeNums:
      ' {nums} device outbound records have been selected. Please select the updated outbound customer'
  },
  peripheralList: {
    isEnable: ' Enable ',
    deletePeripheral: ' Do you want to delete this peripheral?',
    addPeripheral: ' Add Peripheral ',
    editPeripheral: ' Edit peripheral ',
    selectPeripheralType: ' Please select a peripheral type ',
    newAttribute: ' New Attribute ',
    enterPeripheralName: ' Please enter a peripheral name ',
    enterUID: ' Please enter a UID ',
    enterPID: ' Please enter PID ',
    enterAttributeName: ' Please enter an attribute name ',
    attributeNameAlreadyExists: ' The attribute name already exists '
  },
  serviceManagement: {
    customerName: ' Customer Name ',
    storeName: 'Store Name',
    storeId: 'Store ID',
    contacts: ' Contacts',
    phone: ' Contact Information ',
    deadline: ' Deadline ',
    selectDeadline: ' Select Deadline ',
    termOfValidity: ' Valid ',
    deviceUpperLimit: ' Device Upper Limit ',
    enterDeviceUpperLimit: ' Please enter the maximum number of devices you can control ',
    unlimited: ' Unlimited ',
    associatedCustomers: ' Associated Customers ',
    deleteSelectedCustomer: ' Do you want to delete the selected customer?',
    deleteThisCustomer: ' Do you want to delete this customer?',
    deleteSelectedStore: ' Do you want to delete the selected store?',
    deleteThisStore: ' Do you want to delete this store?',
    addAssociatedCustomers: ' Add Associated Customers ',
    enterCustomerName: ' Please enter the customer name ',
    enterStoreName: ' Please enter the store name ',
    customerID: ' Customer ID ',
    enterCustomerID: ' Please enter customer ID ',
    enterContact: ' Please enter a contact',
    newCustomers: ' New Customer ',
    editCustomers: ' Edit Customers ',
    newStore: ' New Store ',
    editStore: ' Edit Store ',
    enterEmail: ' Please enter email ',
    email: ' Mailbox Number ',
    customerProfile: ' Customer Profile ',
    enterCustomerProfile: ' Please enter a customer profile ',
    storeProfile: ' Store Profile ',
    enterStoreProfile: ' Please enter a store profile ',
    freeSpace: ' Free Space ',
    enterFreeSpace: ' Please enter free space ',
    correctContactInfo: ' Please enter the correct contact information ',
    changeCustomerName: ' This customer already exists, please change the customer name！',
    noteAssociatedCustomers:
      ' Note: After setting the associated customer, this customer can add the equipment bound to the associated customer ',
    associatedTime: ' Associated Time ',
    deleteAssociation: ' Delete Association ',
    selectValidPeriod: ' Please select a valid period！',
    deviceMaxTips: 'The maximum value of the device is ',
    orgSelectTips:
      'The customer already exists as a user, and cannot be modified for the organization.'
  },
  serviceAccountManagement: {
    enteremailPhone: 'Please enter email or phone',
    loginAccount: ' Login Account ',
    enterLoginAccount: ' Please enter the login account ',
    loginName: ' Login Name ',
    role: ' Role ',
    selectRole: ' Please select the role',
    accountStatus: ' Account Status ',
    collapseList: ' Collapse List ',
    subAccountList: ' Subaccount List ',
    admin: ' Administrator ',
    operator: ' Operator ',
    customerAdmin: ' Customer Administrator ',
    customerOperator: ' Customer Operator ',
    deleteSelectedAccount: ' Do you want to delete the selected account?',
    deleteThisAccount: ' Do you want to delete this account?',
    newAccount: ' New Account ',
    editAccount: ' Edit Account ',
    name: ' Name',
    enterName: ' Please enter your name ',
    password: ' Account Password ',
    confirmPassword: ' Confirm Password ',
    enterConfirmPassword: ' Please enter the confirmation password ',
    pleaseSelectCustomer: ' Please select a customer ',
    correctMobile: ' Please enter the correct mobile number ',
    correctMobileFormat: ' Please enter the correct phone number format ',
    enterEmailPhnoe: ' Please enter your email or phone number ',
    emailPhone: ' Email/Phone Number ',
    enteremailPhone: ' Please enter your email or phone number'
  },
  serviceRoleManagement: {
    roleName: ' Role Name ',
    enterRoleName: ' Please enter the role name ',
    roleInfo: ' Role Introduction ',
    enterRoleInfo: ' Please enter the role profile ',
    assignPermiss: ' Assign Permissions ',
    deleteSelectedRole: ' Do you want to delete the selected role?',
    deleteThisRole: ' Do you want to delete this role?',
    newRole: ' New Role ',
    editRole: ' Edit Role ',
    allPermiss: ' All Permissions ',
    modul: ' Modular ',
    function: ' Function '
  },
  sourceManagement: {
    resourceTitle: ' Resource Title ',
    enterResourceTitle: ' Please enter the resource title ',
    system: ' System ',
    enable: ' Enable ',
    stop: ' Stop',
    authorizationName: ' Authorization Name ',
    enterAuthorizationName: ' Please enter the authorization name ',
    authorizationURL: ' Authorization URL ',
    enterAuthorizationURL: ' Please enter the authorization URL, for example:/xx/xx',
    enterAuthorizationURL1: ' Please enter the authorization URL ',
    enterAuthorizationURLFormat: ' Please enter the correct authorization URL format ',
    addSubResources: ' Add sub resources ',
    deleteTesourcesTips:
      ' Please confirm the resource to be deleted. If the deleted resource contains sub resources, all sub resources will be invalid！',
    son: 'Sub',
    resources: ' Resources',
    editResources: ' Edit Resources ',
    enterEditResources: ' Please enter the resource name',
    Icon: ' Icon',
    enterIcon: ' Please enter the icon name ',
    sortValue: ' Sort Value',
    enterSortValue: ' Please enter the sorting value ',
    menu: ' Menu ',
    button: ' Button ',
    userName: ' User Name ',
    userID: ' User ID',
    userIP: ' User IP',
    system: ' System ',
    businessDescription: ' Business Description ',
    method: ' Method ',
    requestParams: ' Request Parameters ',
    response: ' Response Results '
  },
  appList: {
    appName: ' Apply Name ',
    enterAppName: ' Please enter the application name ',
    deliveryName: 'Delivery Name',
    uploadApp: ' Upload Application ',
    updateApp: ' Update App ',
    applicableModel: ' Applicable Model ',
    selectApplicableModel: ' Select the applicable model ',
    deleteApp: ' Do you want to delete this app?',
    uploadAppLoading: ' The application is being uploaded, please wait...',
    appIcon: ' Application Icon ',
    enterAppIcon: ' Please upload the application icon ',
    explain: ' Explain ',
    imgSize: ' Picture width and height: 120 X 120;',
    imgFormat: ' Supported image formats: jpg, gif, jpeg, png',
    appInfo: ' Application Introduction ',
    enterAppInfo: ' Please enter the application introduction ',
    appScreenshot: ' Application Screenshot ',
    appScreenshotExplain: ' Please upload application screenshots ',
    versionDesc: ' Version Description ',
    enterVersionDesc: ' Please enter the version description ',
    maxLength: ' The maximum length cannot be greater than 50 characters ',
    uploadaApk: ' Please upload file ',
    uploadaSuccess: ' Upload Succeeded ',
    appDetails: ' Application Details ',
    downloadPackage: ' Download the installation package ',
    appVersion: ' Application Version ',
    appSize: ' App Size ',
    launchTime: ' Launch Time ',
    historicalVersion: ' Historical Version ',
    uploadFileWarnTip: ' Only can upload. apk,. exe document ',
    automaticUpdate: '  Whether to update forcibly ',
    model: 'Model'
  },
  publishApp: {
    selectApp: ' Select Application ',
    selectDevice: ' Select Device ',
    complete: ' Complete ',
    selectApps: ' Select apps to publish ',
    selectListApps: ' Select from the list of apps ',
    forceUpdates: ' Force Updates ',
    autoUpdates: 'Auto Updates',
    updatesTips:
      ' When "Yes" is selected, the device will update the software directly after receiving it. When "no" is selected, the software will silently update when not in use, and when the software is in use, the user will be prompted to confirm whether to update ',
    publishingMethod: ' Publishing Method ',
    selectPublishingMethod: ' Select Publishing Method ',
    selected: ' Selected ',
    fullRelease: ' Full Release ',
    clearAll: ' Clear All ',
    noDevice: ' No equipment is selected temporarily ',
    noDeviceType: ' No device type is selected ',
    common: ' Common ',
    noGroupSelection: ' No group selection ',
    appsPublish: ' Apps to publish ',
    preStep: ' Previous Step ',
    nextStep: ' Next Step ',
    publishNow: ' Publish Now ',
    release: ' Release',
    byCustomer: ' By Customer ',
    publishBySN: ' Publish by SN ',
    publishDevice: ' Issued by Device Model ',
    publishingMethodContent: ' Please select the content of the publishing method ',
    fullReleaseTips:
      ' The total number of publishing devices is more than 3000, please select another publishing method。',
    publishSuccess: ' Publish Successfully ',
    enterDeviceModel: ' Please enter the device model ',
    whole: ' Whole ',
    selectTo: ' Select To ',
    appOf: ' Application Of ',
    uninstall: ' Uninstall ',
    SNTips: ' Please enter SN numbers. Multiple SN numbers are separated by commas "," ',
    add: 'Add',
    addedSN: ' Added SN ',
    ge: ' Individual ',
    enterSNNumbe: ' Please enter the SN number to be added ',
    materialPublish: ' Material to publish'
  },
  operateList: {
    operationName: ' Operation Name ',
    type: ' Type ',
    associatedDevicesNumber: ' Number of Associated Devices ',
    associatedDevices: ' Associated Devices ',
    see: ' See ',
    publishApp: ' Publish App ',
    uninstallApp: ' Uninstall App ',
    deleteRecord: ' Confirm to delete this record?',
    mode: ' Mode ',
    downloadDeviceList: ' Click to download the device list ',
    downloadDeviceList1: ' Download Device List ',
    viewByDevice: ' View by Device ',
    viewByApp: ' View by App ',
    pushSuccee: ' Push Succeeded ',
    downloaded: ' Downloaded ',
    alreadyInstalled: ' Already Installed ',
    noApp: ' No Such App ',
    uninstallComplete: ' Uninstall Complete ',
    notPushed: ' Not Pushed ',
    installFail: ' Install Fail ',
    alreadyPushed: ' Already Pushed ',
    run: ' Running ',
    uninstalled: ' Uninstalled ',
    pushFailed: ' Push Failed ',
    pushStatus: 'Push Status'
  },
  otaList: {
    projectName: ' Project Name ',
    enterProjectName: ' Please enter the project name ',
    addProject: ' Add Project ',
    editProject: ' Edit Project ',
    platform: ' Platform ',
    selectPlatform: ' Select Platform ',
    equipmentNumber: ' Number of Equipment ',
    versionManagement: ' Version Management ',
    deleteItem: ' Do you want to delete this item?',
    projectDesc: ' Project Description ',
    enterProjectDesc: ' Please enter the project description ',
    pleaseSelectPlatform: ' Please select a platform ',
    pleaseSelectSystem: ' Please select an operating system ',
    selectProject: ' Select Item ',
    addVersion: ' Add Version ',
    editVersion: ' Edit Version ',
    versionName: ' Version Name ',
    enterVersionName: ' Please enter the version name ',
    fileSize: ' File Size ',
    describe: ' Describe ',
    deleteThisVersion: ' Do you want to delete this version?',
    enterVersion: ' Please enter the version number ',
    OTAFiles: 'OTA Files',
    pleaseOTAFiles: ' Please upload OTA file ',
    versionLength: ' The maximum length cannot be greater than 40 characters '
  },
  publishOta: {
    publishProject: ' Publish Project ',
    configurePolicy: ' Configure Policy ',
    ItemsPublish: ' Items to Publish ',
    selectItemsPublish: ' Select items to publish ',
    upgradeMethod: ' Upgrade Method ',
    sourceVersion: ' Source Version ',
    selectSourceVersion: 'Please select the source version',
    selectVersion: 'Please select a version',
    enterSingleSN: 'Please enter a single SN number',
    pushContent: 'Push Content',
    batchAddSN: 'Batch add SN',
    batchAddSNNumber: 'Add SN numbers in batches',
    fileFormatTips:
      'Please import files according to the template, and support Excel 2007 and 2003',
    downloadTemplate: 'Download import template',
    noCustomerSelected: 'No customer selected',
    selectPublishingMethod: 'Please select the publishing method',
    addSingleSNNumber: 'Add a single SN number',
    upgradeDetails: 'Upgrade Details',
    boundCustomer: 'Bound Customer'
  },
  statisticalManagement: {
    deviceType: 'Equipment Type',
    yesterday: 'Yesterday',
    last7days: 'Last 7 Days',
    lastMonth: 'Last Month',
    customTimeRange: 'Custom Time Range',
    selectCustomTimeRange: 'Please select a custom time range',
    byDeviceNumber: 'By Equipment Quantity',
    byFirmwareVersion: 'By Firmware Version',
    entryStartTime: 'Please fill in the start time',
    newActiveTrendChart: 'New Active Trend Chart',
    date: 'Date',
    newDevice: 'New Equipment',
    activeDevice: 'Active Equipment',
    versionDistributionMap: 'Version Distribution Map',
    version: 'Version',
    ratio: 'Proportion',
    appInstall: 'Application Installation',
    appInstallChart: 'Trend Chart of Application Installation Volume',
    ranking: 'Ranking',
    installNumber: 'Number of installations',
    byCPUTemperature: 'By CPU temperature',
    byMemoryPercentage: 'By memory percentage',
    byStoragePercentage: 'By storage percentage',
    memoryUsageRatio: 'Memory usage ratio',
    follow: 'Follow',
    above: 'Above',
    storage: 'Storage',
    storageRatio: 'Storage occupancy ratio',
    deviceDistribution: 'Equipment quantity distribution',
    systemDistribution: 'System distribution diagram'
  },
  marketManagement: {
    materialName: 'Material Name',
    uploadMaterial: 'Upload Material',
    editMaterial: 'Edit Palette',
    materialTips:
      'Only png/jpg/gif/mp4/rmvb/wmv files can be uploaded, and the size of a single file cannot exceed 1GB',
    materialTips1: 'The size of a single file cannot exceed 1GB!',
    videoOnly: 'Video can only be uploaded individually!',
    basicInfo: 'Essential Information',
    name: 'Name',
    format: 'Type',
    format: 'Format',
    measurement: 'Measurement',
    size: 'Size',
    programName: 'Program Name',
    launchProgress: 'Launch in progress',
    released: 'To be released',
    complete: 'Complete',
    asOf: 'As of',
    pauseLaunch: 'Do you want to pause the launch?',
    pauseLaunch2: 'Do you want to continue advertising?',
    enterName: 'Please enter a name',
    direction: 'Direction',
    horizontalScreen: 'Horizontal Screen',
    verticalScreen: 'Vertical Screen',
    rotationTime: 'Rotation Time',
    enterRotationTime: 'Please enter the broadcast time',
    material: 'Material',
    releaseTime: 'Release Time',
    custom: 'Custom',
    playFrequency: 'Playback Frequency',
    playTime: 'Play Time',
    allDay: 'All Day',
    publishGroup: 'Publish by Group',
    repeatEveryDay: 'Repeat Every Day',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    selectMaterial: 'Please select material'
  },
  customerHome: {
    warning7Days: 'Equipment warning in recent 7 days',
    runnMemory: 'Running Memory',
    storageCapacity: 'Storage Capacity',
    second: 'Second',
    installAppStatistics: 'Install App Statistics',
    devicesTotal: 'Total number of devices',
    totalDevicesNumber: 'Total number of equipment',
    onlineEquipment: 'On-line Equipment',
    offlineEquipment: 'Off-line Equipment',
    versionStatistics: 'Version Statistics',
    firmwareCloudPlatform: 'Firmware and cloud platform',
    firmwareVersionStatistics: 'Firmware version statistics',
    cloudPlatformVersionStatistics: 'Cloud platform version statistics',
    batchPublish: 'Batch Publish',
    installSpecifiedApp: 'Whether to install the specified application',
    printerShutdown: 'Printer Shutdown',
    enterStore: 'Please enter the store',
    moveGroup: 'Move Group',
    allUsers: 'Available to all users',
    specifyUser: 'The specified user is available',
    userList: 'User List',
    selectedUsers: 'Selected Users',
    enterAppVersion: 'Please enter the application version number',
    cancelAppStartup: 'Select the app to cancel self-startup',
    cancelStartMode: 'Cancel auto start mode',
    selectCancelStartMode: 'Please select the cancel auto start mode',
    allCancelStartup: 'Full cancellation of automatic startup',
    cancelApp: 'App to cancel self-startup',
    cancelAutostartNow: 'Cancel Auto-start Now',
    cancelStartup: 'Cancel Auto Start',
    byGroup: 'By Group',
    bySN: 'By SN',
    byDeviceModel: 'By Equipment Model',
    full: 'Full',
    cancelStartupContent: 'Please select the content to cancel the self-startup mode',
    fullCancelTips:
      'There are more than 3000 devices to cancel the automatic startup in total. Please select another method to cancel the automatic startup.',
    cancelStartupSuccess: 'Cancel auto start successfully',
    enterGroupName: 'Please enter the group name',
    pleaseSelectGroup: 'Please select a group',
    dominateScreenApp: 'Dominate screen application',
    unloadMode: 'Unloading Mode',
    selectUnloadMode: 'Please select the uninstallation method',
    fullUnload: 'Full Unloading',
    appsToUninstall: 'Apps to Uninstall',
    uninstallNow: 'Uninstall Now',
    uninstallByGroup: 'Uninstall by Group',
    uninstallBySN: 'Press SN to Uninstall',
    uninstallByDeviceModel: 'Uninstall by Device Model',
    selectUninstallApp: 'Please select the app to uninstall',
    selectUninstallContent: 'Please select the content of the uninstallation method',
    fullUnloadTips:
      'Please select another unloading method if the total number of unloading devices is more than 3000.'
  },
  groupManagement: {
    groupName: 'Group Name',
    groupInfo: 'Group Introduction',
    devicesNumber: 'Number of Equipment',
    manageDevices: 'Manage Devices',
    deleteSelectedGroup: 'Do you want to delete the selected group?',
    deleteThisGroup: 'Do you want to delete this group?',
    addGroup: 'Add Group',
    editGroup: 'Edit Group',
    addDeviceToGroup: 'Add device to group',
    singleAdd: 'Single Add',
    batchAdd: 'Batch Add',
    addMethod: 'Add Method',
    batchAddGroupTemplate: 'Batch Add Equipment to Grouping Template',
    addGroupTips:
      'Note: Please fill in strictly according to the template, otherwise the addition may fail',
    modifyGroup: 'Modify Group',
    modifySuccess: 'Modification Succeeded!',
    curDeviceNumber: 'Current equipment quantity',
    removeDevice: 'Do you want to remove the device?',
    removeSuccess: 'Removal Succeeded!',
    endLaunch: 'Do you want to end the launch?',
    flexibleMode: 'Flexible Mode',
    flexibleModeStandby: 'Standby in flexible mode',
    autoRestore: 'Automatically resume program delivery after',
    autoRestoreTips: 'Note: Click the screen in flexible mode to exit the program',
    selectDeliveryMethod: 'Please select the delivery method',
    fullRelease: 'Full Release',
    launchNow: 'Launch Immediately',
    launchByGroup: 'Launch by Group',
    launchBySN: 'Launch by SN',
    launchByModel: 'Launch by Equipment Model',
    launch: 'Launch',
    selectLaunchTime: 'Please select the release time',
    selectPlayTime: 'Please select the playing time',
    selectLaunchContent: 'Please select the content of the delivery method',
    fullReleaseTips:
      'If the total number of equipment is more than 3000, please choose another delivery method.',
    launchSuccess: 'Launch Succeeded!',
    order: 'Order',
    selectUser: 'Select User',
    time: 'Time'
  },
  customerStatistical: {
    InstallStatistics: 'Installation Statistics',
    InstallTrends: 'Installation Trends',
    appInstallChart: 'Statistical Chart of Application Installation Quantity',
    reissue: 'Reissue',
    export: 'Export',
    InstallFailed: 'Installation Failed',
    InstallSuccess: 'Installation Succeeded',
    isReissue: 'Whether to confirm reissue?',
    reissueSuccess: 'Reissue Succeeded!',
    install: 'Install',
    active: 'Active',
    warningType: 'Alert Type',
    warningConfig: 'Alert Configuration',
    warningTime: 'Warning time',
    subScreenWarning: 'Sub screen warning',
    warningContent: 'Early warning content',
    PeripheralExceptionWarning: 'Peripheral exception warning',
    PeripheralException: 'Peripheral device exception',
    PeripheralExceptionTips: 'Alarm when the switch open',
    CPUHeatWarning: 'CPU temperature warning',
    CPUHeatTips: 'Alarm when the temperature exceeds the set temperature',
    runMemoryWarning: 'Running memory alert',
    occupiedMemoryTips: 'Alarm when the occupied memory is greater than the set proportion',
    storageWarning: 'Storage capacity warning',
    setProportionWarning:
      'Alarm when the occupied storage capacity is greater than the set proportion',
    use: 'use',
    cellPhone: 'Cell-phone number',
    enterCellPhone: 'Please enter your mobile number',
    savePicture: 'Save as Picture',
    warningType1: 'CPU temperature is too high, exceeding {nums}℃',
    warningType2: 'Not much running memory remaining, with a usage rate exceeding {nums}%',
    warningType3: 'Not much storage space remaining, with a usage rate exceeding {nums}%',
    warningType4: 'Printer error',
    warningType5: ' The secondary screen is not working properly ',
    warningType6: ' Camera error',
    warningType7: ' Scanner equipment error',
    warningType8: ' Electronic scale error',
    warningTypeOther: ' Other errors ',
    electronicScalesRemind: 'Electronic scale Remind Setting',
    electronicScalesReminderFrequency: 'Remind Frequency',
    electronicScalesReminderFixedFrequency: 'Fixed Time',
    electronicScalesReminderDesignateFrequency: 'Designate Time',
    electronicScalesReminderFrequencyUnit: ' Hours',
    electronicScalesReminderTime: ' Remind Time',
    electronicScalesReminderDevice: 'Device Bind',
    electronicScalesReminderDeviceAll: 'All Device',
    electronicScalesReminderDeviceDesignate: 'Specified Equipment',
    oneDeviceAtleast: 'Select a Device Please',
    timeInput: 'Please input remind time',
    timeRepeats: 'There exist same remind time',
    timeFrequency: 'Please select time frequency ',
    peripheralSelfTest: 'Peripheral self-checking Setting',
    peripheralSelfTestInfo: 'Maximum weight deviation',
    peripheralSelfTestTips:
      'The value can be entered in grams and greater than zero. When the positive or negative deviation value of the electronic scale exceeds the maximum deviation value entered, the self-check result of the electronic scale will be marked as abnormal.'
  },
  electronicScalesReminder: {
    sn: 'SN',
    group: 'Device Group',
    store: 'Store',
    remindTime: 'Remind Time',
    executeTime: 'Execute Time',
    startTime: 'Start Time',
    endTime: 'End Time',
    deviceModel: 'Device Model',
    unexecuted: ' Unexecuted '
  },
  deviceSelfTest: {
    sn: 'SN',
    selfTestResult: 'Self Test Result',
    finalSelfTestTime: 'Final Self Test Time',
    model: 'Device Model',
    group: 'Device Group',
    store: 'Store',
    startTime: 'Start Time',
    endTime: 'End Time',
    operateTime: 'Operate Time',
    operateUserName: 'Operate User',
    operateContent: 'Operate Content',
    operateRecord: 'Operate Record',
    selfTestDetail: 'Self Test Detail',
    deviceName: 'Device Name',
    deviceType: 'Device Type',
    userSelfTestResult: 'User Self Test Result',
    connectTest: 'Connect Test',
    printTest: 'Print Test',
    weighTestDeviation: 'Weigh Test Deviation',
    selfTestTime: 'Self Test Time',
    setToNormal: 'Set To Normal',
    operationRecords: ' Operation Records '
  },
  organManagement: {
    parentOrgan: 'Higher Organization',
    organ: 'Organization',
    orgName: 'Organization name',
    enterOrgName: 'Please enter the organization name',
    remark: 'Remark',
    enterRemark: 'Please enter remark',
    selectOrganTip: 'Please Select organization',
    associateMerchantNum: 'Number of associate merchant',
    associateMerchant: 'Associate merchant',
    merchantId: 'Merchant Id',
    merchantName: 'Merchant Name',
    deleteThisOrgan: ' Do you want to delete this organ?'
  },
  basicsData: {
    首页: 'Home',
    设备管理: 'Device Management',
    设备监控: 'Device Monitor',
    设备列表: 'Equipment List',
    系统管理: 'System Management',
    客户管理: 'Customer Management',
    门店管理: 'Store Management',
    设备型号管理: 'Equipment Model Management',
    分组管理: 'Group Management',
    应用管理: 'Application Management',
    应用列表: 'App List',
    版本管理: 'Version Management',
    发布应用: 'Publish Application',
    账号管理: 'Account Management',
    角色管理: 'Role Management',
    用户管理: 'User Management',
    客户账号管理: 'Customer Account Management',
    客户角色管理: 'Customer Role Management',
    资源管理: 'Resource Management',
    操作日志: 'Operation Log',
    '设备列表-详情': 'Equipment List - Details',
    '设备列表-编辑': 'Equipment List - Edit',
    '设备列表-删除': 'Equipment list - Delete',
    '设备列表-新增': 'Equipment List - New',
    '设备型号管理-新增': 'Equipment Model Management - New',
    '设备型号管理-编辑': 'Equipment Model Management - Edit',
    '设备型号管理-删除': 'Equipment Model Management - Delete',
    '客户管理-新增': 'Customer Management - New',
    '客户管理-编辑': 'Customer Management - Edit',
    '客户管理-删除': 'Customer Management - Delete',
    '门店管理-新增': 'Store Management - New',
    '门店管理-编辑': 'Store Management - Edit',
    '门店管理-删除': 'Store Management - Delete',
    '客户账号管理-新增': 'Customer Account Management - Add',
    '客户账号管理-编辑': 'Customer Account Management - Edit',
    '客户账号管理-删除': 'Customer Account Management - Delete',
    '客户账号管理-子账号列表': 'Customer Account Management Sub Account List',
    '客户账号管理-修改密码': 'Customer Account Management - Change Password',
    '客户角色管理-新增': 'Customer Role Management - Add',
    '客户角色管理-编辑': 'Customer Role Management - Edit',
    '客户角色管理-删除': 'Customer Role Management - Delete',
    '客户角色管理-分配权限': 'Customer Role Management - Assign Permission',
    '资源管理-新增': 'Resource Management - New',
    '资源管理-编辑': 'Resource Management - Edit',
    '资源管理-删除': 'Resource Management - Delete',
    '资源管理-新增子资源': 'Resource Management - Add Sub Resource',
    '设备列表-发布': 'Equipment List - Publish',
    '分组管理-新增': 'Group Management - New',
    '分组管理-编辑': 'Group Management - Edit',
    '分组管理-删除': 'Group Management - Delete',
    '分组管理-管理设备': 'Group Management - Manage Devices',
    '应用列表-新增': 'Application List - New',
    '应用列表-编辑': 'Application List - Edit',
    '应用列表-删除': 'Application list - delete',
    '应用列表-详情': 'Application List - Details',
    '版本管理-详情': 'Version Management - Details',
    '版本管理-下载': 'Version Management - Download',
    '版本管理-删除': 'Version Management - Delete',
    '账号管理-新增': 'Account Management - New',
    '账号管理-编辑': 'Account Management - Edit',
    '账号管理-删除': 'Account Management - Delete',
    '账号管理-修改密码': 'Account Management - Change Password',
    '用户管理-新增': 'User Management - New',
    '用户管理-编辑': 'User Management - Edit',
    '用户管理-删除': 'User Management - Delete',
    '用户管理-修改密码': 'User Management - Change Password',
    '角色管理-新增': 'Role Management - New',
    '角色管理-编辑': 'Role Management - Edit',
    '角色管理-删除': 'Role Management - Delete',
    '角色管理-分配权限': 'Role Management - Assign Permission',
    全部激活设备: 'Activate all devices',
    卸载应用: 'Uninstall APP',
    处理进度: 'Processing Progress',
    '处理进度-详情': 'Processing Progress - Details',
    基础预警管理: 'Basic Alert Management',
    '基础预警管理-配置': 'Basic Alert Management - Configuration',
    电子秤设置: 'Electronic Scales Setting',
    '设备列表-远程协助': 'Device List - Remote Assistance',
    '设备列表-远程桌面': 'Device List - Remote Desktop',
    '设备列表-远程文件': 'Device List - Remote File',
    设备出库: 'Equipment Issue',
    '设备出库-新增': 'Equipment Issue - New',
    '设备出库-编辑': 'Equipment Issue - Edit',
    云平台升级: 'Cloud Platform Upgrade',
    统计分析: 'Statistical Analysis',
    设备统计: 'Equipment Statistics',
    应用统计: 'Application Statistics',
    电子秤提醒记录: 'Electronic Scales Reminder Statistics',
    外设自检查询: ' Peripheral Self Check Query ',
    系统OS统计: 'System OS Statistics',
    OTA升级: 'OTA Upgrade',
    项目列表: 'Project List',
    推送升级: 'Push Upgrade',
    升级进度: 'Upgrade Progress',
    '升级进度-详情': 'Upgrade Progress - Details',
    版本统计: 'Version Statistics',
    基础状态统计: 'Basic Status Statistics',
    '设备出库-删除': 'Equipment Issue - Delete',
    '设备列表-关闭wif': 'Device List - Close Wi-Fi',
    '设备列表-关闭定位': 'Device List - Turn Off Positioning',
    '设备列表-开启定位': 'Device List - Enable Positioning',
    '设备列表-定时重启': 'Device List - Scheduled Restart ',
    '设备列表-文件取回': 'Device List - File Retrieval ',
    '设备列表-添加子分组': 'Device List - Add Subgrouping',
    '设备列表-重命名子分组': 'Device List - Rename Subgroups',
    '设备列表-删除子分组': 'Device List - Delete Sub-groups',
    '设备列表-关闭虚拟按键': 'Device List - Close Virtual Key',
    '设备列表-开启虚拟按键': 'Device List - Enable Virtual Key',
    '设备列表-设置时区': 'Device List - Set Time Zone',
    '设备列表-设置休眠': 'Device List - Set Sleep',
    '设备列表-重启': 'Device List Restart',
    '设备列表-关机': 'Device List - Shutdown',
    '设备列表-恢复出厂设置': 'Device List - Restore Factory Settings',
    '分组管理-关闭wif': 'Group Management - Close Wi-Fi',
    '分组管理-关闭虚拟按键': 'Group Management - Close Virtual Keys',
    '分组管理-开启虚拟按键': 'Group Management - Enable Virtual Keys',
    '分组管理-设置时区': 'Group Management - Set Time Zone',
    '分组管理-设置休眠': 'Group Management - Set Sleep',
    '分组管理-重启': 'Group Management Restart',
    '分组管理-关机': 'Group Management Shutdown',
    '分组管理-恢复出厂设置': 'Group Management - Restore Factory Settings',
    '客户管理-关联客户': 'Customer Management - Associated Customer',
    自启动设置: 'Autostart Settings',
    '设备列表-文件传输': 'Device List - File Transfer',
    营销管理: 'Marketing Management',
    素材管理: 'Material Management',
    节目投放: 'Program Delivery',
    '设备列表-屏幕亮度设置': 'Device List - Screen Brightness Setting',
    '设备列表-字体大小设置': 'Device List - Font Size Setting',
    外设配置: 'Peripheral Configuration',
    '设备列表-设备分组': 'Device List - Equipment Grouping',
    '设备列表-限制安装应用': 'Device List - Restricted Installation of Applications',
    '设备列表-允许安装应用': 'Device List - Allow App Installation ',
    机构管理: 'Organization Management',
    '机构管理-新增': 'Organization Management - New',
    '机构管理-编辑': 'Organization Management - Edit',
    '机构管理-删除': 'Organization Management - Detele'
  },
  permissionName: {
    查询设备: 'Query Equipment',
    应用列表查询: 'Application List Query',
    查看查询日志: 'View Query Log',
    查看设备详情: 'View device details',
    修改设备: 'Modify Equipment',
    删除设备: 'Delete Device',
    新增设备: 'New Equipment',
    新增设备型号: 'New Equipment Model',
    修改设备型号: 'Modify Equipment Model',
    删除设备型号: 'Delete Device Model',
    新增客户: 'New Customer',
    修改客户: 'Modify Customer',
    删除客户: 'Delete Customer',
    新增客户账号: 'New Customer Account',
    修改客户账号: 'Modify Customer Account',
    删除客户账号: 'Delete Customer Account',
    新增客户角色: 'New Customer Role',
    修改客户角色: 'Modify Customer Role',
    删除客户角色: 'Delete Customer Role',
    新增资源: 'New Resources',
    更新资源管理: 'Update Resource Management',
    删除资源管理: 'Delete Resource Management',
    向设备发布命令: 'Issue Command To Device',
    新增分组: 'New Group',
    修改分组: 'Modify Group',
    删除分组: 'Delete Group',
    新增应用: 'New Application',
    修改应用: 'Modify Application',
    删除应用: 'Delete App',
    应用详情: 'Application Details',
    版本详情: 'Version Details',
    版本下载: 'Version Download',
    删除版本: 'Delete Version',
    新增账号: 'New Account',
    修改账号: 'Modify Account',
    删除账号: 'Delete Account',
    新增角色: 'New Role',
    修改角色: 'Modify Role',
    删除角色: 'Delete Role',
    查询操作日志: 'Query Operation Log',
    远程协助: ' Remote Assistance',
    设备分组: ' Equipment Grouping '
  },
  ecxelHeader: {
    sn: 'SN (required)',
    deviceType: 'Device Type (required)',
    spId: 'Service Provider ID (required)',
    printerId: 'Printer ID',
    scanningEquipmentId: 'Scanning device ID',
    cameraId: 'Camera ID',
    electronicScalesId: 'Electronic scale ID',
    store: ' Store '
  },
  ecxelHeader2: {
    head1: 'SN (required)',
    head2: 'Device Type',
    head3: 'Firmware Version',
    head4: 'Service Provider Name ID (required)',
    head5: 'Group Name (required)',
    head6: 'Address',
    head7: 'Country',
    head8: 'Province',
    head9: 'City',
    head10: 'Area'
  },
  ecxelHeader3: {
    head1: 'SN (required)',
    head2: 'Group Name (required)',
    head3: 'Country',
    head4: 'Province',
    head5: 'City',
    head6: 'Area'
  }
}
