import axios from '../lib/request.js'

const api = {
  //首页统计
  getCountSummary: data => axios.post('/device/statistics/statisticAnalysis', data),

  //登录
  login: data => axios.post('/login', data),

  //登出
  logout: data => axios.get('/logout'),
  //获取远程访问地址
  statisticAnalysis: params => axios.get('/device/remoteSedAssistance', { params }),
  //远程关闭
  remoteClose: params => axios.get('/device/remoteClose', { params })
}

export default api
